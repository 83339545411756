import React from 'react'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import { LazyBlocksFactory } from '../components/LazyBlocksFactory/LazyBlocksFactory'

interface Props {
  data: any
  pageContext: any
}
const GetinTouch = ({ data, pageContext }: Props) => {
  const { wpPage } = data
  const renderedBlocks = LazyBlocksFactory(wpPage.lazy_data, wpPage.title)
  return (
    <Layout>
     
      {renderedBlocks.map((v) => v)}
   
     
    </Layout>
  )
}

export default GetinTouch

export const pageQuery = graphql`
  query getInTouch {
    wpPage(title: { eq: "Get in Touch" }) {
      id
      lazy_data
      title
    }
  }
`
